import {
  ArrowDropDown,
  ArrowDropUp,
  RadioButtonUnchecked,
  CheckCircleOutline,
} from "@mui/icons-material"
import { useState } from "react"
import { RowAlignCenterDiv } from "styles/ReportsStyle"
import styled from "styled-components"
import { ResultDetails } from "./ResultDetails"
import { InspectResult, Status } from "@sevensense/sev-inspect-result-types-ts"

const UL = styled.ul`
  list-style-type: none;
`

export interface ResultProps {
  data: InspectResult
}

const NestedResult = ({ data }: ResultProps) => {
  const [showChildren, setShowChildren] = useState(false)
  // We do not show children of status `Skip`.
  const nonSkippedChildren = data.children.filter((child) => child.status !== Status.Skip)
  const hasChildren = nonSkippedChildren.length > 0

  const title = [`check_name=${data.check_name}`]
  if (data.duration_s > 0) {
    title.push(`duration=${data.duration_s}s`)
  }

  return (
    <div>
      <RowAlignCenterDiv
        onClick={() => setShowChildren((toggle) => !toggle)}
        style={{ cursor: hasChildren ? "pointer" : "auto" }}
      >
        {data.status === Status.Pass && (
          <CheckCircleOutline style={{ color: "green", marginRight: "10px" }} />
        )}
        {data.status === Status.Fail && (
          <RadioButtonUnchecked style={{ color: "red", marginRight: "10px" }} />
        )}
        <span>{data.check_name}</span>
        {hasChildren &&
          (showChildren ? (
            <ArrowDropUp style={{ color: "lightgray", fontSize: "30px" }} />
          ) : (
            <ArrowDropDown style={{ color: "lightgray", fontSize: "30px" }} />
          ))}
      </RowAlignCenterDiv>

      {data.reason.metadata && <ResultDetails result={data}></ResultDetails>}

      {hasChildren && showChildren && (
        <UL>
          {nonSkippedChildren.map((child: InspectResult, ix: number) => (
            <li key={ix}>
              <NestedResult data={child} />
            </li>
          ))}
        </UL>
      )}
    </div>
  )
}

export const Result = ({ data }: ResultProps) => {
  return (
    <UL>
      {data.children
        .filter((child) => child.status !== Status.Skip)
        .map((child: InspectResult, ix: number) => (
          <li key={ix}>
            <NestedResult data={child} />
          </li>
        ))}
    </UL>
  )
}
